/* eslint-disable camelcase */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { translateForm } from 'utilities/form-utils'
import { media } from 'utilities/styled'
import { isObject } from 'utilities/utils'

import {
  BidsProviderIds,
  CarFileStatusEnumLowerCase,
  CarFileStatusEnumUpperCase,
} from 'config/enums'
import configForms from 'config/forms'

import useDynamicEndpointDone from 'hooks/use-dynamic-endpoint-done'
import { useCarFileType } from 'hooks/use-car-file-type'

import { nextPrevCloseButtonsPropTypes } from 'components/molecules/next-prev-close-buttons'
import Typography from 'components/molecules/typography'
import Modal from 'components/molecules/modal'
import CalculationForm from 'components/organisms/calculation-form'
import LegendBoxes from 'components/molecules/legend-boxes'
import LegendPriceBox from 'components/molecules/legend-price-box'

const MissingDataModal = styled(Modal)`
  ${media.tablet`
    .MuiDialog-container {
      align-items: center;
    }
  `}
`

const PriceChecks = ({
  carFileId,
  carLicensePlate,
  carDateFirstAdmission,
  dynamicEndpoint,
  postDynamicEndpoint,
  openOverlay,
  getBids,
  bidForms,
  bids,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState({})
  const [missingDataModalOpen, setMissingDataModalOpen] = useState(false)
  const bidsData = bids && bids.data

  useDynamicEndpointDone(
    dynamicEndpoint,
    () => {
      getBids({ auto_id: carFileId })
    },
    [getBids, dynamicEndpoint],
  )

  const getBidData = (payload) => {
    const bidData = { ...payload }
    if (payload.bids && payload.bids.length > 1) {
      bidData.tradingPrice = payload.bids[payload.bids.length - 2]
      bidData.sellPrice = payload.bids[payload.bids.length - 1]
    }
    return bidData
  }

  const priceCheck = async ({ button, id }) => {
    const { endpoint } = button

    setLoading({
      ...loading,
      [id]: true,
    })

    if (endpoint) {
      await postDynamicEndpoint({
        endpoint,
      })
    }

    return setLoading({
      ...loading,
      [id]: false,
    })
  }

  const indicataBidsData = bidsData?.find(
    ({ id }) => id === BidsProviderIds.INDICATA,
  )
  const jpcarsBidsData = bidsData?.find(
    ({ id }) => id === BidsProviderIds.JPCARS,
  )

  const showIndicata = isObject(indicataBidsData)
  const showJpcars = isObject(jpcarsBidsData)

  // When both indicata and jpcars are not an object, return early and do not show anything
  if (!showIndicata && !showJpcars) {
    return null
  }

  // Gets the most recent bid data for indicata and autotelex
  const indicata = indicataBidsData ? getBidData(indicataBidsData) : {}
  const jpcars = jpcarsBidsData ? getBidData(jpcarsBidsData) : {}

  return (
    <>
      <LegendBoxes>
        {showIndicata && (
          <LegendPriceBox
            image="/images/logo-indicata.png"
            linkText={t('priceCheck')}
            loading={loading.indicata}
            onClickLink={() => priceCheck(indicata)}
            tradingPrice={indicata?.tradingPrice?.value ?? undefined}
            sellPrice={indicata?.sellPrice?.value ?? undefined}
          />
        )}
        {showJpcars && (
          <LegendPriceBox
            image="/images/logo-jpcars.png"
            legendColor="brandSupport"
            linkText={t('priceCheck')}
            loading={loading.jpcars}
            onClickLink={() => priceCheck(jpcars)}
            tradingPrice={jpcars?.tradingPrice?.value ?? undefined}
            sellPrice={jpcars?.sellPrice?.value ?? undefined}
          />
        )}
      </LegendBoxes>
      <MissingDataModal
        title={t('missingValuationDataModalTitle')}
        showCloseButton
        open={missingDataModalOpen}
        closeHandler={() => setMissingDataModalOpen(false)}
      >
        <Typography type="BodyParagraph">
          {t('missingValuationDataModalText')}
        </Typography>
      </MissingDataModal>
    </>
  )
}

PriceChecks.propTypes = {
  carFileId: PropTypes.string.isRequired,
  carLicensePlate: PropTypes.string,
  carDateFirstAdmission: PropTypes.string,
  postDynamicEndpoint: PropTypes.func.isRequired,
  dynamicEndpoint: PropTypes.object,
  openOverlay: PropTypes.func.isRequired,
  getBids: PropTypes.func.isRequired,
  bids: PropTypes.object,
  bidForms: PropTypes.object.isRequired,
}

PriceChecks.defaultProps = {
  carLicensePlate: null,
  carDateFirstAdmission: null,
  bids: [],
  dynamicEndpoint: null,
}

function TopdownCalculationForm({
  car,
  config,
  dynamicEndpoint,
  getBids,
  getForms,
  bids,
  bidForms,
  forms,
  onSubmit,
  openOverlay,
  previous,
  postDynamicEndpoint,
  isFinalStep,
  isValuation,
  hasStickyNav,
  hasChevronInNextPrevCloseButtons,
  optionButtonText,
  onOptionButtonClick,
  match,
  nextPrevCloseButtonsProps,
  ...restProps
}) {
  const { t } = useTranslation()
  const { isCarShareCarFile } = useCarFileType()

  const {
    auto_id: carId,
    kenteken: carLicensePlate,
    toelating: carDateFirstAdmission,
  } = car

  const constructedForms = {
    ...forms,
    salesValue: configForms.topDown.data.salesValue,
  }

  const topDownTaxation = {}
  const topDownTaxationInitValues = {}

  if (config) {
    config.forEach((i) => {
      topDownTaxationInitValues[i.field] = null
      topDownTaxation[i.field] = { ...i }
    })
  }

  /**
   * Generic function for caculating a resulting topDown Value
   * The reason for this is that they can be either fixed values
   * or relative ones based on a percentage of
   * the resale value ('verkoopwaarde').
   * please note that is uses `topDownTaxtationValues` and
   * `topDownTaxation` internally.
   * @param {string} identifier of the topDown config.
   */
  function topDownCalculationFor(topDownItem) {
    return ({ verkoop }) => {
      if (
        topDownTaxation[topDownItem] &&
        topDownTaxation[topDownItem].percentage
      ) {
        // The provided value is a percentage, which means the resulting
        // value needs to be that percentage of the resale value (`verkoopwaarde`).
        const result =
          verkoop * (Number(topDownTaxation[topDownItem].value) / 100)
        // Make a distinction between the original value provided by the backend and
        // the value casted to a Number since an empty string or null is casted to 0 by Number
        const minValue = topDownTaxation[topDownItem].min_value
        const maxValue = topDownTaxation[topDownItem].max_value
        const minValueNumber = Number(topDownTaxation[topDownItem].min_value)
        const maxValueNumber = Number(topDownTaxation[topDownItem].max_value)
        const valueToLow =
          minValue !== undefined &&
          minValue !== null &&
          minValue !== '' &&
          result < minValueNumber
        const valueToHigh =
          maxValue !== undefined &&
          maxValue !== null &&
          maxValue !== '' &&
          result > maxValueNumber
        if (valueToHigh) {
          return maxValueNumber
        }
        if (valueToLow) {
          return minValueNumber
        }
        return result
      }

      if (topDownTaxation[topDownItem] && topDownTaxation[topDownItem].value) {
        return topDownTaxation[topDownItem].value
      }
    }
  }

  const calculationForms = {
    topdown_inruilwaarde: translateForm(
      constructedForms.topdown_inruilwaarde.fieldsets,
      t,
    ),
  }
  let formsOrdering = ['topdown_inruilwaarde']

  let totalFormula = null
  const recokostenInitValues = {}

  if (car.soort !== 'huur' && car.soort !== 'handel') {
    calculationForms.topdown_taxatiewaarde = translateForm(
      constructedForms.topdown_taxatiewaarde.fieldsets,
      t,
    )
    calculationForms.topdown_recokosten = translateForm(
      constructedForms.topdown_recokosten.fieldsets,
      t,
    )
    calculationForms.topdown_recokosten.forEach((fieldSet) => {
      fieldSet.fields.forEach((field) => {
        recokostenInitValues[field.name] = field.value
      })
    })

    formsOrdering = [
      'topdown_recokosten',
      'topdown_taxatiewaarde',
      ...formsOrdering,
    ]

    totalFormula = ({
      taxatiewaarde,
      korting_nw, // eslint-disable-line
      korting_gw, // eslint-disable-line
    }) => Number(taxatiewaarde) + Number(korting_gw) + Number(korting_nw) // eslint-disable-line
  } else {
    calculationForms.salesValue = translateForm(
      constructedForms.salesValue.fieldsets,
      t,
    )

    formsOrdering = ['salesValue', ...formsOrdering]

    totalFormula = ({
      verkoop,
      korting_nw, // eslint-disable-line
      korting_gw, // eslint-disable-line
    }) => Number(verkoop) + Number(korting_gw) + Number(korting_nw) // eslint-disable-line
  }

  const freeze = isCarShareCarFile
    ? !car?.carshare_can_bid
    : car.status ===
        CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.binnen] ||
      car.status ===
        CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.verkocht] ||
      car.status ===
        CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.afgeleverd]

  return (
    <CalculationForm
      freeze={freeze}
      priceChecks={
        (isValuation || isCarShareCarFile) && (
          <PriceChecks
            carFileId={carId}
            carLicensePlate={carLicensePlate}
            carDateFirstAdmission={carDateFirstAdmission}
            bids={bids}
            bidForms={bidForms}
            dynamicEndpoint={dynamicEndpoint}
            postDynamicEndpoint={postDynamicEndpoint}
            getBids={getBids}
            openOverlay={openOverlay}
          />
        )
      }
      initialData={{
        kosten_tot: null,
        taxatiewaarde: null,
        inruilwaarde: null,
        ...topDownTaxationInitValues,
        korting_nw: null,
        korting_gw: null,
        ...car,
        ...recokostenInitValues,
        verkoop: car.verkoop || null,
      }}
      forms={calculationForms}
      totalFields={['kosten_tot', 'taxatiewaarde', 'inruilwaarde']}
      formulas={[
        {
          outputField: 'winstmarge',
          formula: topDownCalculationFor('winstmarge'),
        },
        {
          outputField: 'onderhandelingsmarge',
          formula: topDownCalculationFor('onderhandelingsmarge'),
        },
        {
          outputField: 'garantiekosten',
          formula: topDownCalculationFor('garantiekosten'),
        },
        {
          outputField: 'algemenekosten',
          formula: topDownCalculationFor('algemenekosten'),
        },
        {
          outputField: 'kosten_tot',
          formula: ({
            kosten_opt, // eslint-disable-line
            kosten_tech, // eslint-disable-line
            import_transport_kosten,
            import_belasting,
          }) =>
            Number(kosten_opt) +
            Number(kosten_tech) +
            Number(import_transport_kosten) +
            Number(import_belasting),
        },
        {
          outputField: 'taxatiewaarde',
          formula: ({
            verkoop,
            kosten_tot,
            winstmarge,
            onderhandelingsmarge,
            garantiekosten,
            algemenekosten,
          }) => {
            const result =
              Number(verkoop) -
              (Number(kosten_tot) +
                Number(winstmarge) +
                Number(onderhandelingsmarge) +
                Number(garantiekosten) +
                Number(algemenekosten))
            return result
          },
        },
        {
          outputField: 'inruilwaarde',
          // 'inruilwaarde' is benificial for the customer,
          // so they get the 'korting'.
          formula: totalFormula,
        },
      ]}
      formsOrdering={formsOrdering}
      onSubmit={onSubmit}
      previous={previous}
      isFinalStep={isFinalStep}
      {...restProps}
      nextPrevCloseButtonsProps={nextPrevCloseButtonsProps}
      hasStickyNav={hasStickyNav}
      hasChevronInNextPrevCloseButtons={hasChevronInNextPrevCloseButtons}
      optionButtonText={optionButtonText}
      onOptionButtonClick={onOptionButtonClick}
      valuation
    />
  )
}

TopdownCalculationForm.propTypes = {
  bids: PropTypes.object,
  forms: PropTypes.object.isRequired,
  bidForms: PropTypes.object.isRequired,
  carId: PropTypes.string,
  car: PropTypes.object.isRequired,
  config: PropTypes.array.isRequired,
  getBids: PropTypes.func.isRequired,
  getForms: PropTypes.func.isRequired,
  postDynamicEndpoint: PropTypes.func.isRequired,
  dynamicEndpoint: PropTypes.object,
  openOverlay: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  previous: PropTypes.bool,
  isFinalStep: PropTypes.bool,
  isValuation: PropTypes.bool,
  hasStickyNav: PropTypes.bool,
  hasChevronInNextPrevCloseButtons: PropTypes.bool,
  /** Text in option button below Next Prev controls */
  optionButtonText: PropTypes.string,
  /** Function called from option button below Next Prev controls */
  onOptionButtonClick: PropTypes.func,
  match: PropTypes.object.isRequired,
  nextPrevCloseButtonsProps: PropTypes.shape(nextPrevCloseButtonsPropTypes),
}

TopdownCalculationForm.defaultProps = {
  bids: null,
  carId: null,
  dynamicEndpoint: null,
  previous: false,
  isFinalStep: false,
  isValuation: false,
  hasStickyNav: false,
  hasChevronInNextPrevCloseButtons: false,
  optionButtonText: null,
  onOptionButtonClick: null,
}

export default TopdownCalculationForm
