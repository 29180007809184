import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { ThemeContext } from 'styled-components/macro'

import { media } from 'utilities/styled'
import { getAppTheme } from 'utilities/utils'

import { AppThemes } from 'config/enums'

import Button from 'components/atoms/button'
import Counter from 'components/atoms/counter'
import FramedImage from 'components/atoms/framed-image'
import Link from 'components/atoms/link'
import DelphiAIChatBotButton from 'components/molecules/delphi-ai-chat-bot-button'
import NavigationBarNews from 'components/molecules/navigation-bar-news'
import { useSelector } from 'react-redux'

const Container = styled.header`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.brandBravo};
  display: flex;
  position: relative;
  padding: 0 ${({ theme }) => theme.sizings.lvl2};
  height: ${({ theme }) => theme.heights.mobileHeaderHeight};

  ${media.phone`
    padding: 0 24px;
  `}
`

const UccLogo = styled(FramedImage)`
  height: 34px;
  width: 60px;
`

const AutopresentatieLogo = styled(FramedImage)`
  height: auto;
  width: 100px;
  flex-shrink: 0;

  img {
    vertical-align: middle;
  }
`

const ToDriveLogo = styled(FramedImage)`
  height: auto;
  width: 100px;
  flex-shrink: 0;

  img {
    vertical-align: middle;
  }
`

const CaAutoFinanceLogo = styled(FramedImage)`
  height: auto;
  width: 68px;
  flex-shrink: 0;

  img {
    vertical-align: middle;
  }
`

const Line = styled.div`
  background-color: ${({ theme }) => theme.colors.brandCharlie};
  height: 100%;
  width: 1px;
  flex-shrink: 0;
  margin: 0 ${({ theme }) => theme.sizings.lvl0};

  ${media.phone`
    margin: 0 ${({ theme }) => theme.sizings.lvl2};
  `}
`

const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  height: inherit;
  margin-left: auto;
  padding: 16px 0;
`

const NotificationContainer = styled.div`
  position: relative;
  flex-shrink: 0;
`

const StyledCounter = styled(Counter)`
  position: absolute;
  top: -${({ theme }) => theme.sizings.lvl0};
  right: 0;

  ${media.tablet`
    right: -${({ theme }) => theme.sizings.lvl0};
  `}
`

function MobileHeader({
  className,
  menuOpen,
  openSearchOverlay,
  openTodoOverlay,
  setMenuOpen,
  todos,
  showPopout,
  setShowPopout,
  notificationCount,
  newsItem,
  ...restProps
}) {
  const { t } = useTranslation()
  const { themeName } = getAppTheme()
  const theme = useContext(ThemeContext)

  const { logoMark } = theme
  const menuIcon = menuOpen ? 'close' : 'menu'
  const todosCount = todos && todos.data && todos.data.count
  const hasAccessToDelphiAiChatBot = useSelector(
    (state) => !!state.auth?.userDetails?.chatbot,
  )

  function toggleMenu() {
    setMenuOpen(!menuOpen)
  }

  return (
    <Container className={className} {...restProps}>
      <Link to="/">
        {themeName === AppThemes.UCC && (
          <UccLogo alt="UCC Logo" src={logoMark} />
        )}
        {themeName === AppThemes.AUTOPRESENTATIE && (
          <AutopresentatieLogo alt="Autopresentatie Logo" src={logoMark} />
        )}
        {themeName === AppThemes.TODRIVE && (
          <ToDriveLogo alt="2Drive Logo" src={logoMark} />
        )}
        {themeName === AppThemes.CA_AUTO_FINANCE && (
          <CaAutoFinanceLogo alt="CA Auto Finance Logo" src={logoMark} />
        )}
      </Link>
      <ButtonsContainer>
        <Button icon="search" level="minimal" onClick={openSearchOverlay} />

        {newsItem && theme.showNewsFeed && (
          <>
            <Line />
            <NavigationBarNews
              showPopout={showPopout}
              setShowPopout={setShowPopout}
              notificationCount={notificationCount}
              newsItem={newsItem}
            />
          </>
        )}
        <Line />
        <NotificationContainer>
          {!!todosCount && <StyledCounter count={todosCount} />}
          <Button
            icon="todo"
            iconSize="lg"
            level="minimal"
            onClick={openTodoOverlay}
            data-test-e2e="button-todos"
          />
        </NotificationContainer>
        <Line />
        {/* <Link>
          <Button icon={item.icon} text={item.label} to={item.link} alignLeft />
        </Link> */}
        {hasAccessToDelphiAiChatBot && (
          <>
            <DelphiAIChatBotButton />
            <Line />
          </>
        )}
        <Button
          ariaLabel={t('mainMenu')}
          level="minimal"
          icon={menuIcon}
          onClick={toggleMenu}
          data-test-e2e="button-main-menu"
        />
      </ButtonsContainer>
    </Container>
  )
}

MobileHeader.propTypes = {
  className: PropTypes.string,
  menuOpen: PropTypes.bool.isRequired,
  openSearchOverlay: PropTypes.func.isRequired,
  openTodoOverlay: PropTypes.func.isRequired,
  setMenuOpen: PropTypes.func.isRequired,
  todos: PropTypes.object,
  showPopout: PropTypes.bool.isRequired,
  setShowPopout: PropTypes.func.isRequired,
  notificationCount: PropTypes.number.isRequired,
  newsItem: PropTypes.shape({
    img: PropTypes.string,
    title: PropTypes.string.isRequired,
    intro: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }),
}

MobileHeader.defaultProps = {
  className: null,
  todos: null,
  newsItem: null,
}

export default MobileHeader
