import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { BidsProviderIds } from 'config/enums'

import { getBids, getPriceValuation } from 'redux/actions/data'
import { getForms } from 'redux/actions/forms'

import { media } from 'utilities/styled'

import Text from 'components/atoms/text'

import useDealerLocationCountryCode from 'hooks/use-location-country-code'

import ProviderContent from './carfile-quick-price-valuation-content'

const Title = styled(Text)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};
`

const ProvidersRows = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: space-between;
  gap: ${({ theme }) => theme.sizeByFactor(1)};

  ${media.tablet`
      flex-direction: row;
      gap: ${({ theme }) => theme.sizeByFactor(2)};
  `}
`

const ProvidersRow = styled.div`
  min-height: ${({ theme }) => theme.sizeByFactor(9.25)};
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.alternativeBackground};

  ${media.tablet`
    border-radius: ${({ theme }) => theme.sizings.lvl0};
    padding: ${({ theme }) => theme.sizeByFactor(0.75)} ${({ theme }) => theme.sizeByFactor(1.5)};
    min-width: ${({ theme }) => theme.sizeByFactor(40)};
  `}
`

const ProviderImage = styled.img`
  display: block;
  width: ${({ theme }) => theme.sizeByFactor(22)};
  height: auto;
  padding: ${({ theme }) => theme.sizings.lvl2};

  ${media.tablet`
    width: ${({ theme }) => theme.sizeByFactor(19)};
  `}
`

const GET_PRICE_VALUATION_ID = 'getPriceValuation'

function CarFileQuickPriceValuation({ className }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  // loading is an array of strings, which include the provider ID when it is loading. E.g. ['indicata'] when only indicata is loading, ['indicata', 'jpcars'] when both are clicked in the UI
  const [loading, setLoading] = useState([])
  const carId = useSelector((state) => state?.data?.carfile?.data?.auto_id)
  const carLicensePlate = useSelector(
    (state) => state?.data?.carfile?.data?.kenteken,
  )
  const dateFirstAccepted = useSelector(
    (state) => state?.data?.carfile?.data?.toelating,
  )
  const bidsData = useSelector((state) => state?.data?.bids?.data)
  const getPriceValuationData = useSelector(
    (state) => state?.data?.[GET_PRICE_VALUATION_ID]?.data,
  )
  const { locationCountryCodeIsNl } = useDealerLocationCountryCode()

  useEffect(() => {
    dispatch(getForms('bids', { auto_id: carId }))
    dispatch(getBids({ auto_id: carId }))
  }, [carId, getPriceValuationData, dispatch])

  const handlePriceCheck = async (id) => {
    setLoading((ids) => [...ids, id])

    // Error handling is handled in the getPriceValuation function
    // It'll show a toast when the call fails
    await dispatch(
      getPriceValuation(GET_PRICE_VALUATION_ID, {
        auto_id: carId,
        service: id,
      }),
    )

    setLoading((ids) => ids.filter((oldId) => oldId !== id))
  }

  const indicataBidsData = bidsData?.find(
    ({ id }) => id === BidsProviderIds.INDICATA,
  )
  const jpcarsBidsData = bidsData?.find(
    ({ id }) => id === BidsProviderIds.JPCARS,
  )

  const getBidData = (payload) => {
    const bidData = { ...payload }
    if (payload.bids && payload.bids.length > 1) {
      bidData.tradingPrice = payload.bids[payload.bids.length - 2]
      bidData.sellPrice = payload.bids[payload.bids.length - 1]
    }
    return bidData
  }

  const indicataBids = indicataBidsData ? getBidData(indicataBidsData) : {}
  const jpcarsBids = jpcarsBidsData ? getBidData(jpcarsBidsData) : {}

  if (!carId) {
    console.warn(
      'Car ID not found in Redux store. Use component in context of a car file.',
    )
    return null
  }

  if (!locationCountryCodeIsNl) {
    console.warn(
      "Car dealer is based outside of the Netherlands. Not showing the quick price valuation due to API's not available outside of the Netherlands.",
    )
    return null
  }

  if (!carLicensePlate && !dateFirstAccepted) {
    console.warn(
      "Car does not have a license and not first date expected. Not showing the quick price valuation due to API's not available fetching information without these requirements.",
    )
    return null
  }

  return (
    <div className={className}>
      <Title
        type="h2"
        text={t('carFileQuickPriceValuation.quickPriceValuation')}
      />
      <ProvidersRows>
        {!!carLicensePlate && (
          <ProvidersRow>
            <ProviderImage
              src="/images/logo-indicata.png"
              alt="Indicata logo"
            />
            <ProviderContent
              isLoading={loading.includes(BidsProviderIds.INDICATA)}
              handlePriceCheck={() =>
                handlePriceCheck(BidsProviderIds.INDICATA)
              }
              tradingPrice={indicataBids?.tradingPrice?.value}
              sellPrice={indicataBids?.sellPrice?.value}
            />
          </ProvidersRow>
        )}
        <ProvidersRow>
          <ProviderImage src="/images/logo-jpcars.png" alt="JP.cars logo" />
          <ProviderContent
            isLoading={loading.includes(BidsProviderIds.JPCARS)}
            handlePriceCheck={() => handlePriceCheck(BidsProviderIds.JPCARS)}
            tradingPrice={jpcarsBids?.tradingPrice?.value}
            sellPrice={jpcarsBids?.sellPrice?.value}
          />
        </ProvidersRow>
      </ProvidersRows>
    </div>
  )
}

CarFileQuickPriceValuation.propTypes = {
  className: PropTypes.string,
}

export default CarFileQuickPriceValuation
