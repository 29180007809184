import React from 'react'
import styled from 'styled-components/macro'

import languages from 'utilities/language'
import { getAppTheme } from 'utilities/utils'

import theme from 'config/theme'
import { Languages, AppThemes } from 'config/enums'

const LOADING_TRANSLATIONS_LOGO_PROPS = {
  [AppThemes.UCC]: {
    alt: 'GO remarketing logo',
    src: '/images/go-logo-2024.svg',
  },
  [AppThemes.AUTOPRESENTATIE]: {
    alt: 'Customer Connect - Autopresentatie logo',
    src: '/images/cc-logo.svg',
  },
  [AppThemes.TODRIVE]: {
    alt: '2Drive logo',
    src: '/images/2drive-logo.svg',
  },
  [AppThemes.CA_AUTO_FINANCE]: {
    alt: 'CA Auto Finance logo',
    src: '/images/ca-auto-finance-logo.svg',
  },
}

const StyledContainerDiv = styled.div`
  background-color: ${({ $themeName }) =>
    theme[$themeName].colors.brandFoxtrot || 'inherit'};
`

const StyledContentDiv = styled.div`
  color: ${({ $themeName }) => theme[$themeName].colors.text || 'inherit'};
`

const LoadingTranslations = () => {
  const languageCode = languages.getLanguage()
  const { themeName } = getAppTheme()

  const getLoadingMessage = (languageCode) => {
    switch (languageCode) {
      case Languages.FR: {
        return 'Initialisez le site Web.'
      }
      case Languages.EN: {
        return 'Initialising website.'
      }
      case Languages.DE: {
        return 'Website initialisieren.'
      }
      // When no language is detected, fall back to the default language (NL)
      default: {
        return 'Website initialiseren.'
      }
    }
  }

  const getOneMomentMessage = (languageCode) => {
    switch (languageCode) {
      case Languages.FR: {
        return 'Un instant...'
      }
      case Languages.EN: {
        return 'One moment...'
      }
      case Languages.DE: {
        return 'Ein Moment...'
      }
      // When no language is detected, fall back to the default language (NL)
      default: {
        return 'Één moment...'
      }
    }
  }

  /**
   * We're not using a CSS-in-JS solution (e.g. styled components) but regular "className" properties instead.
   * This component gets loaded before Styled Components` `ThemeProvider` is initialized.
   *
   * The CSS for this component can be found in the global styles: ./src/styles/global.css
   */
  return (
    <StyledContainerDiv
      $themeName={themeName}
      className={`ucc_atom_loading-translations${themeName !== AppThemes.UCC ? ` ucc_atom_loading-translations--${themeName}` : ''}`}
    >
      <div className="ucc_atom_loading-translations_container ucc_atom_loading-translations_container--with-logo-offset">
        <img
          className="ucc_atom_loading-translations_logo"
          src={LOADING_TRANSLATIONS_LOGO_PROPS[themeName].src} // maps to the src of 2Drive, UCC or Autopresentatie
          alt={LOADING_TRANSLATIONS_LOGO_PROPS[themeName].alt} // maps to the alt of 2Drive, UCC or Autopresentatie
        />
        <StyledContentDiv
          $themeName={themeName}
          className="ucc_atom_loading-translations_content"
        >
          <span className="ucc_atom_loading-translations_spinner">
            <span className="ucc_atom_loading-translations_spinner_ring" />
            <span className="ucc_atom_loading-translations_spinner_ring" />
            <span className="ucc_atom_loading-translations_spinner_ring" />
            <span className="ucc_atom_loading-translations_spinner_ring" />
          </span>
          <div className="ucc_atom_loading-translations_content_text">
            <p className="ucc_atom_loading-translations_content_paragraph">
              {getLoadingMessage(languageCode)}
            </p>
            <p className="ucc_atom_loading-translations_content_paragraph">
              {getOneMomentMessage(languageCode)}
            </p>
          </div>
        </StyledContentDiv>
      </div>
    </StyledContainerDiv>
  )
}

export default LoadingTranslations
