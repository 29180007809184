import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled, { ThemeContext } from 'styled-components/macro'
import { Helmet } from 'react-helmet'
import { css } from 'styled-components'

import { AppThemes } from 'config/enums'

import { media } from 'utilities/styled'
import { getAppTheme } from 'utilities/utils'

import Link from 'components/atoms/link'
import FramedImage from 'components/atoms/framed-image'
import AuthFooter from 'components/molecules/auth-footer'
import PayoffPoster from 'components/molecules/payoff-poster'

const Container = styled.div`
  ${media.desktop`
    display: flex;
    height: 100vh;
  `}
`

const Aside = styled.aside`
  display: none;

  ${media.desktop`
    display: flex;
    flex: 1 1 auto;
  `}
`

const Main = styled.main`
  background-color: ${({ theme }) => theme.colors.brandFoxtrot};
  min-height: 100vh;

  ${media.desktop`
    display: block;
    flex: 0 0 640px;
    flex-direction: column;
    overflow: scroll;
    padding: 88px 120px 16px;
  `}
`

const Header = styled.header`
  padding: 40px;
  text-align: center;

  ${media.desktop`
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    text-align: left;
  `}
`

const Footer = styled(AuthFooter)`
  margin-top: auto;
  padding: 40px;
  text-align: center;

  ${media.desktop`
    padding: 40px 0 0;
    text-align: left;
  `}
`

const SharedAppLogoStyles = css`
  width: 75%;
  margin: auto;
  display: block;
  text-align: center;
`

const Logo = styled(FramedImage)`
  ${SharedAppLogoStyles}
`

const CaAutoFinanceLogo = styled(FramedImage)`
  ${SharedAppLogoStyles}
  max-width: 153px;
`

function AuthLayout({ children, pageTitle }) {
  const { t } = useTranslation()
  const { themeName } = getAppTheme()
  const theme = useContext(ThemeContext)

  const { themeNameCamelCase } = getAppTheme()

  return (
    <Container>
      <Helmet
        title={`${pageTitle} - ${t(`themeOptions.${themeNameCamelCase}.productName`)}`}
      />
      <Aside>
        <PayoffPoster />
      </Aside>
      <Main>
        <Header>
          <Link to="/">
            {themeName === AppThemes.CA_AUTO_FINANCE ? (
              <CaAutoFinanceLogo src={theme.logo} alt="CA Auto Finance Logo" />
            ) : (
              <Logo src={theme.logo} alt="logo" />
            )}
          </Link>
        </Header>
        {children}
        <Footer />
      </Main>
    </Container>
  )
}

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string.isRequired,
}

export default AuthLayout
