import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'
// eslint-disable-next-line import/no-cycle
import Menu from 'components/organisms/menu'

const Container = styled.div`
  position: relative;
  display: inline-block;
  width: auto;

  .actual-menu {
    box-shadow: ${({ theme }) => theme.shadows.bottomRight};
    ${({ positionMenu, theme }) => `
      ${positionMenu.toRight ? `left: calc(100% + ${theme.sizings.lvl2}); top: 0;` : ''}
      ${positionMenu.toLeft ? `right: calc(100% + ${theme.sizings.lvl2}); top: 0;` : ''}
      ${positionMenu.toTop ? `bottom: calc(100% + ${theme.sizings.lvl2}); left: 0;` : ''}
      ${positionMenu.toBottom ? `top: calc(100% + ${theme.sizings.lvl2}); left: 0;` : ''}
    `}
    position: absolute;
    min-width: 200px;
    z-index: 101;
  }
`

/**
 * The PopupMenu can open up to the left, right, top and bottom.
 *
 * How the PopupMenu is opened is completely controlled by the item triggering it.
 * This can be any UI component or process in the application that is desired.
 * This means other interaction (such as mouseover/hover) could trigger it too.
 *
 * See the examples below for further details.
 */
function PopupMenu({
  children,
  customWrappingElement,
  isMenuActive,
  items,
  positionMenu,
  title,
  ...restProps
}) {
  return (
    <Container
      positionMenu={positionMenu}
      as={customWrappingElement}
      {...restProps}
    >
      {children}
      {isMenuActive && (
        <Menu
          title={title}
          className="actual-menu"
          variant="popup"
          isMenuActive
          items={items}
          data-test-e2e="popup-menu"
          // only passed as a styling parameter:
        />
      )}
    </Container>
  )
}

PopupMenu.propTypes = {
  positionMenu: PropTypes.shape({
    toTop: PropTypes.bool,
    toLeft: PropTypes.bool,
    toBottom: PropTypes.bool,
    toRight: PropTypes.bool,
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      label: PropTypes.string.isRequired,
      to: PropTypes.string,
    }),
  ).isRequired,
  customWrappingElement: PropTypes.string,
  children: PropTypes.node.isRequired,
  isMenuActive: PropTypes.bool,
  title: PropTypes.node,
}

PopupMenu.defaultProps = {
  customWrappingElement: null,
  isMenuActive: false,
  title: null,
}

export default PopupMenu
