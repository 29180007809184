import FramedImage from 'components/atoms/framed-image'
import Icon from 'components/atoms/icon'
import LoadingIndicator from 'components/atoms/loading-indicator'
import Text from 'components/atoms/text'
import TextLink from 'components/atoms/text-link'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getNews } from 'redux/actions/data'
import styled from 'styled-components/macro'
import { media } from 'utilities/styled'
import { clip } from 'utilities/utils'

const Header = styled.div`
  display: flex;
  margin-bottom: 24px;
  justify-content: space-between;
  flex-direction: column;

  ${media.tablet`
    flex-direction: row;
  `}
`

const HeaderTitleContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};

  & > *:first-child {
    margin-right: 8px;
  }

  ${media.tablet`
    margin-bottom: 0;
  `}
`

const HeaderButtonsContainer = styled.div`
  align-items: center;
  display: flex;
`

const Seperator = styled.div`
  background: ${({ theme }) => theme.colors.brandDelta};
  flex: 0 0 auto;
  height: 100%;
  margin: 0 16px;
  width: 1px;
`

const StyledDiv = styled.div`
  flex-grow: 1;
`

const Title = styled(Text)`
  margin-bottom: 16px;
`

const Body = styled(Text)`
  margin-bottom: 24px;

  img {
    max-width: 100%;
  }
`

const MainContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  ${media.tablet`
    flex-direction: row;
  `}
`

const Image = styled(FramedImage)`
  flex: 0 0 auto;
  width: 240px;
  margin: auto;
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};

  & > img {
    height: auto;
    max-width: 100%;
  }

  ${media.tablet`
    margin-bottom: auto;
  `}
`

/**
 * This component fetches and renders an RSS news feed from
 * https://go-remarketing.com/actueel/
 *

 */
function NewsFeed({ className }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const items = useSelector((state) => state.data.news)
  const [selectedItem, setSelectedItem] = useState(0)

  useEffect(() => {
    dispatch(getNews())
  }, [dispatch])

  if (!items || items.loading) {
    return <LoadingIndicator />
  }

  const itemsData = items.data
  const maxItemIndex = itemsData.length - 1
  const item = itemsData[selectedItem]

  function handleClickNext() {
    setSelectedItem(clip(selectedItem + 1, 0, maxItemIndex))
  }

  function handleClickPrevious() {
    setSelectedItem(clip(selectedItem - 1, 0, maxItemIndex))
  }

  return (
    <div className={className}>
      <Header>
        <HeaderTitleContainer>
          <Icon type="published" color="brandAlfa" height="28" />
          <Text text={t('latestNews')} type="h3" />
        </HeaderTitleContainer>
        <HeaderButtonsContainer>
          <TextLink onClick={handleClickPrevious} text={t('previous')} />
          <Seperator />
          <TextLink onClick={handleClickNext} text={t('next')} />
        </HeaderButtonsContainer>
      </Header>
      <MainContainer>
        <Image alt={t('newsItemImage')} src={item.img} />
        <Seperator />
        <StyledDiv>
          <Title type="h3" text={item.title} />
          <Body text={item.intro} />
          <TextLink href={item.link} text={t('readMore')} />
        </StyledDiv>
      </MainContainer>
    </div>
  )
}

NewsFeed.propTypes = {
  className: PropTypes.string,
}

NewsFeed.defaultProps = {
  className: null,
}

export default NewsFeed
