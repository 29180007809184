import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import useDeepCompareEffect from 'use-deep-compare-effect'

import marketviewData from 'utilities/marktview-data'
import storage from 'utilities/storage'
import {
  formatLicenseplate,
  getObjectFromQueryString,
  handleChangeQuery,
} from 'utilities/utils'

import { InputParamsContext } from 'contexts/marketview'

import { getMarketviewData } from 'redux/actions/marketview-data'

import { marketViewFilterIds } from 'config/data'

import LoadingIndicator from 'components/atoms/loading-indicator'
import StaytimeLabel from 'components/atoms/staytime-label'
import Text from 'components/atoms/text'
import TextLink from 'components/atoms/text-link'
import LegendInputBox from 'components/molecules/legend-input-box'
import LegendPriceBox from 'components/molecules/legend-price-box'
import MarketviewTableFilters from 'components/molecules/marketview-table-filters'
import EnhancedTable from 'components/organisms/enhanced-table'
import { BidsProviderIds } from 'config/enums'

import useAbortController from 'hooks/use-abort-controller'
import useMarketviewStoragePrefix from 'hooks/use-marketview-storage-prefix'

const Head = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: ${({ theme }) => theme.sizings.lvl4};
  margin-bottom: ${({ theme }) => theme.sizings.lvl4};
`

const Title = styled(Text)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};
`

const InfoText = styled(Text)`
  display: block;
`

const ExpandedRowTitle = styled(Text)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl1};
`

const LegendsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: ${({ theme }) => theme.sizings.lvl2};
`

const StyledTableFilters = styled(MarketviewTableFilters)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl3};
`

function ExpandedRow({ data, orderBy, orderDirection, page, resultsPerPage }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const inputParams = useContext(InputParamsContext)
  const [loading, setLoading] = useState({})
  const { ref: priceCheckAbortControllerRef, abort: abortPriceCheckRequest } =
    useAbortController()
  const {
    ref: stockSellTheseAbortControllerRef,
    abort: abortStockSellTheseRequest,
  } = useAbortController()

  const indicata = {
    tradingPrice:
      data.valuations.data &&
      data.valuations.data.find(
        // eslint-disable-next-line camelcase
        ({ type_valuation, subtype }) =>
          // eslint-disable-next-line camelcase
          type_valuation === BidsProviderIds.INDICATA &&
          subtype === 'handelswaarde',
      ),
    sellPrice:
      data.valuations.data &&
      data.valuations.data.find(
        // eslint-disable-next-line camelcase
        ({ type_valuation, subtype }) =>
          // eslint-disable-next-line camelcase
          type_valuation === BidsProviderIds.INDICATA &&
          subtype === 'verkoopwaarde',
      ),
  }
  const autoTelex = {
    tradingPrice:
      data.valuations.data &&
      data.valuations.data.find(
        // eslint-disable-next-line camelcase
        ({ type_valuation, subtype }) =>
          // eslint-disable-next-line camelcase
          type_valuation === BidsProviderIds.AUTOTELEX &&
          subtype === 'handelswaarde',
      ),
    sellPrice:
      data.valuations.data &&
      data.valuations.data.find(
        // eslint-disable-next-line camelcase
        ({ type_valuation, subtype }) =>
          // eslint-disable-next-line camelcase
          type_valuation === BidsProviderIds.AUTOTELEX &&
          subtype === 'verkoopwaarde',
      ),
  }

  async function priceCheck(type) {
    setLoading({
      ...loading,
      [type]: true,
    })

    abortPriceCheckRequest()

    await dispatch(
      getMarketviewData(
        'priceCheck',
        {
          carId: data.carId.data,
          type_valuation: type,
        },
        priceCheckAbortControllerRef.current.signal,
      ),
    )

    abortStockSellTheseRequest()

    await dispatch(
      getMarketviewData(
        'stockSellThese',
        [
          ...inputParams,
          {
            key: 'stocktime',
            value: String(data.stockTime.data),
          },
          {
            key: 'rows',
            value: String(resultsPerPage),
          },
          {
            key: 'rowstart',
            value: ((page - 1) * resultsPerPage).toString(),
          },
          {
            key: 'orderby',
            value: orderBy,
          },
          {
            key: 'orderdirection',
            value: orderDirection,
          },
        ],
        stockSellTheseAbortControllerRef.current.signal,
      ),
    )

    setLoading({
      ...loading,
      [type]: false,
    })
  }

  return (
    <>
      <ExpandedRowTitle type="h5" text={t('requestRestValueIndication')} />
      <LegendsContainer>
        <LegendPriceBox
          image="/images/logo-indicata.png"
          linkText={t('priceCheck')}
          loading={loading.indicata}
          onClickLink={() => priceCheck(BidsProviderIds.INDICATA)}
          tradingPrice={indicata.tradingPrice && indicata.tradingPrice.value}
          sellPrice={indicata.sellPrice && indicata.sellPrice.value}
          parentIsDarker
        />
        <LegendPriceBox
          image="/images/autotelex.png"
          legendColor="brandSupport"
          linkText={t('priceCheck')}
          loading={loading.restwaarde}
          onClickLink={() => priceCheck(BidsProviderIds.AUTOTELEX)}
          tradingPrice={autoTelex.tradingPrice && autoTelex.tradingPrice.value}
          sellPrice={autoTelex.sellPrice && autoTelex.sellPrice.value}
          parentIsDarker
        />
      </LegendsContainer>
    </>
  )
}

ExpandedRow.propTypes = {
  data: PropTypes.object.isRequired,
  orderBy: PropTypes.string.isRequired,
  orderDirection: PropTypes.oneOf(['asc', 'desc']).isRequired,
  page: PropTypes.number.isRequired,
  resultsPerPage: PropTypes.number.isRequired,
}

const debouncedStockTimeUpdate = debounce(
  (stockTimeUpdate, targetStaytimeStorageId, newStockTime) => {
    stockTimeUpdate(newStockTime)
    storage.setPermanent(targetStaytimeStorageId, newStockTime)
  },
  200,
)

/**
 * Component to fetch and show missed sales outside disctrict
 * data.
 *

 */

function MarketviewPriceCheck() {
  const history = useHistory()
  const location = useLocation()
  const inputParams = useContext(InputParamsContext)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const storagePrefix = useMarketviewStoragePrefix()
  const token = useSelector(({ auth }) => auth?.instanceId)
  const targetStaytimeStorageId = `${storagePrefix}${marketViewFilterIds.targetStayTime}`
  const targetstaytime =
    getObjectFromQueryString(location.search).targetStayTime ||
    storage.getValue(targetStaytimeStorageId)
  const targetStayTime = targetstaytime || 8
  const [temporaryStockTime, setTemporaryStockTime] = useState(targetStayTime)
  const [page, setPage] = useState(1)
  const resultsPerPage = 24
  const [orderBy, setOrderBy] = useState('licenseplate')
  const [orderDirection, setOrderDirection] = useState('asc')
  const carsToSell = useSelector(
    (state) =>
      state.marketviewData &&
      state.marketviewData.data &&
      state.marketviewData.data.stockSellThese &&
      (state.marketviewData.data.stockSellThese.recordSet === null
        ? []
        : state.marketviewData.data.stockSellThese.recordSet),
  )
  const totalCarsToSell = useSelector(
    (state) =>
      state.marketviewData &&
      state.marketviewData.data &&
      state.marketviewData.data.stockSellThese &&
      state.marketviewData.data.stockSellThese.totalRecords &&
      state.marketviewData.data.stockSellThese.totalRecords[0].total,
  )
  const error = useSelector(
    (state) =>
      state.marketviewData &&
      state.marketviewData.error &&
      state.marketviewData.error.stockSellThese,
  )
  const {
    ref: stockSellTheseAllAbortControllerRef,
    abort: abortStockSellTheseAllRequest,
  } = useAbortController()
  const {
    ref: stockSellTheseAllExportAbortControllerRef,
    abort: abortStockSellTheseAllExportRequest,
  } = useAbortController()
  const {
    ref: stockSellTheseAbortControllerRef,
    abort: abortStockSellTheseRequest,
  } = useAbortController()

  const columns = [
    {
      id: 'licenseplate',
      orderId: 'licenseplate',
      label: t('licensePlate'),
    },
    {
      id: 'brand',
      orderId: 'brand',
      label: t('brand'),
    },
    {
      id: 'model',
      orderId: 'model',
      label: t('model'),
    },
    {
      id: 'type',
      orderId: 'type',
      label: t('type'),
    },
    {
      id: 'yearOfBuild',
      orderId: 'buildYear',
      label: t('yearOfBuild'),
    },
    {
      id: 'stocktime',
      orderId: 'stocktime',
      label: t('staytime'),
    },
    {
      id: 'value',
      exportId: 'salesPrice',
      label: t('value'),
    },
  ]

  function getParams(all) {
    let params = [
      ...inputParams,
      {
        key: 'stocktime',
        value: String(targetStayTime),
      },
      {
        key: 'orderby',
        value: orderBy,
      },
      {
        key: 'orderdirection',
        value: orderDirection,
      },
    ]

    if (all) {
      params = [
        ...params,
        {
          key: 'rowstart',
          value: '0',
        },
      ]
    } else {
      params = [
        ...params,
        {
          key: 'rows',
          value: resultsPerPage.toString(),
        },
        {
          key: 'rowstart',
          value: ((page - 1) * resultsPerPage).toString(),
        },
      ]
    }

    return params
  }

  function fetchTableData(all) {
    const fetchId = all ? 'stockSellTheseAll' : 'stockSellThese'

    if (!inputParams) {
      return Promise.resolve()
    }

    const params = getParams(all)

    let signal

    if (fetchId === 'stockSellThese') {
      abortStockSellTheseRequest()
      signal = stockSellTheseAbortControllerRef.current.signal
    }

    if (fetchId === 'stockSellTheseAll') {
      abortStockSellTheseAllRequest()
      signal = stockSellTheseAllAbortControllerRef.current.signal
    }

    return dispatch(getMarketviewData(fetchId, params, signal))
  }

  function handleChangeStockTime(newTarget) {
    setTemporaryStockTime(newTarget)
    debouncedStockTimeUpdate(
      (newStaytime) =>
        handleChangeQuery(
          location,
          history,
          marketViewFilterIds.targetStayTime,
          newStaytime,
        ),
      targetStaytimeStorageId,
      newTarget,
    )
  }

  useDeepCompareEffect(() => {
    if (!inputParams) {
      return
    }
    fetchTableData()
  }, [dispatch, inputParams, targetStayTime, page, orderBy, orderDirection])

  if (!Array.isArray(carsToSell)) {
    return <LoadingIndicator error={error} />
  }

  function handleOrder(direction, by) {
    setOrderDirection(direction)
    setOrderBy(by)
  }

  function handleDownloadAll() {
    const params = getParams(true)

    abortStockSellTheseAllExportRequest()

    marketviewData.getMarketviewExport({
      type: 'stockSellTheseAll',
      token,
      body: params,
      queryParams: {
        source: 'pricing',
      },
      signal: stockSellTheseAllExportAbortControllerRef.current.signal,
    })
  }

  return (
    <>
      <Head>
        <div>
          <Title text={t('stockValuation')} type="h2" />
          <InfoText text={t('stockValuationText')} type="floatingLabelLabel" />
        </div>
        <LegendInputBox
          legendColor="actionsCta"
          label={t('meanStayTimeGoal')}
          onChange={handleChangeStockTime}
          unit={t('days')}
          value={temporaryStockTime}
        />
      </Head>
      <StyledTableFilters />
      <EnhancedTable
        columns={columns}
        rows={carsToSell.map((carToSell) => ({
          licenseplate: {
            component: carToSell.carId ? (
              <TextLink
                text={formatLicenseplate(carToSell.licenseplate)}
                href={`/car/${carToSell.carId}`}
              />
            ) : (
              <Text text={formatLicenseplate(carToSell.licenseplate)} />
            ),
            data: carToSell.licenseplate,
          },
          brand: {
            component: <Text text={carToSell.make} />,
            data: carToSell.make,
          },
          model: {
            component: <Text text={carToSell.model} />,
            data: carToSell.model,
          },
          type: {
            component: <Text text={carToSell.type} />,
            data: carToSell.type,
          },
          yearOfBuild: {
            component: <Text text={carToSell.buildYear} />,
            data: carToSell.buildYear,
          },
          stocktime: {
            component: <StaytimeLabel days={carToSell.stocktime} />,
            data: carToSell.stocktime,
          },
          value: {
            component: (
              <>
                <Text
                  text={
                    carToSell.salesPrice
                      ? `€ ${carToSell.salesPrice}`
                      : t('unknown')
                  }
                />
                <TextLink text={t('priceCheck')} />
              </>
            ),
            data: carToSell.salesPrice || t('unknown'),
          },
          carId: {
            data: carToSell.carId,
          },
          stockTime: {
            data: targetStayTime,
          },
          valuations: {
            data: carToSell.valuations,
          },
        }))}
        ExpandedRow={ExpandedRow}
        selectedText={t('carsSelected')}
        onChangePage={setPage}
        onDownloadAll={handleDownloadAll}
        onOrder={handleOrder}
        orderBy={orderBy}
        orderDirection={orderDirection}
        page={page}
        resultsPerPage={resultsPerPage}
        totalResults={totalCarsToSell}
        noDataMessage={t('noCarFoundBasedOnFilters')}
      />
    </>
  )
}

MarketviewPriceCheck.propTypes = {}

MarketviewPriceCheck.defaultProps = {}

export default MarketviewPriceCheck
