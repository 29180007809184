import React from 'react'
import { useTranslation } from 'react-i18next'

import * as SimpleTabs from 'components/layouts/simple-tabs/index'
import * as ROUTES from 'config/routes'
import PropTypes from 'prop-types'
import CarBpmDeclarationStep1Form from '../bpm-declaration/steps/car-bpm-declaration-step-1-form'
import CarEntryForm from './all-entry-forms/car-entry-forms'
import StartCspCalculation from './all-entry-forms/start-csp-calculation'

function AllEntryFormsWidget({ allowedFeatures, ...restProps }) {
  const { t } = useTranslation()

  const tabItems = []
  if (allowedFeatures.valuation) {
    tabItems.push({
      value: 'valuation',
      label: t('AllEntryFormsWidget.tab.valuation.label'),
    })
  }
  if (allowedFeatures.entry) {
    tabItems.push({
      value: 'entry',
      label: t('AllEntryFormsWidget.tab.entry.label'),
    })
  }
  if (allowedFeatures.bpm) {
    tabItems.push({
      value: 'bpm',
      label: t('AllEntryFormsWidget.tab.bpm.label'),
    })
  }
  if (allowedFeatures.csp) {
    tabItems.push({
      value: 'csp',
      label: t('AllEntryFormsWidget.tab.csp.label'),
    })
  }

  const [activeTab, setActiveTab] = React.useState(tabItems[0]?.value || '')

  return (
    <section {...restProps}>
      {tabItems.length > 0 && (
        <SimpleTabs.Root
          value={activeTab}
          onValueChange={(newTab) => setActiveTab(newTab)}
        >
          <SimpleTabs.List items={tabItems} size="small" alwaysSmall />
          {allowedFeatures.valuation && (
            <SimpleTabs.Content value="valuation">
              <CarEntryForm isValuation />
            </SimpleTabs.Content>
          )}
          {allowedFeatures.entry && (
            <SimpleTabs.Content value="entry">
              <CarEntryForm />
            </SimpleTabs.Content>
          )}
          {allowedFeatures.bpm && (
            <SimpleTabs.Content value="bpm">
              <CarBpmDeclarationStep1Form
                redirectToOnSubmit={`${ROUTES.CAR_BPM_DECLARATION}/1`}
              />
            </SimpleTabs.Content>
          )}
          {allowedFeatures.csp && (
            <SimpleTabs.Content value="csp">
              <StartCspCalculation />
            </SimpleTabs.Content>
          )}
        </SimpleTabs.Root>
      )}
    </section>
  )
}

AllEntryFormsWidget.propTypes = {
  allowedFeatures: PropTypes.shape({
    entry: PropTypes.bool.isRequired,
    valuation: PropTypes.bool.isRequired,
    bpm: PropTypes.bool.isRequired,
    csp: PropTypes.bool.isRequired,
  }).isRequired,
}

export default AllEntryFormsWidget
